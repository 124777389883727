import { React, useEffect, useState } from 'react';
import GeoGebraP from '../components/GeoGebraP';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
// import '../App.css';
import GetProblemInfo from '../components/GetProblemInfo';
import axiosapi from "../api/axiosapi";
import ProblemStatDescription from '../components/ProblemStatDescription';
import useAuth from '../hooks/useAuth';
import UserSolvingStat from '../components/UserSolvingStat';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

const TodayProblemFromImage = ()=> {
    const {proID}=useParams();
    const navigateto = useNavigate();
    const {todayProblem} = useLocation().state;
    const location = useLocation();
    const {auth} = useAuth();
    const {problem, problemPoemTitle, problemPoem, errP} = GetProblemInfo({"problemID": todayProblem.problemCode});
    const {useStat} = UserSolvingStat({"problemCode": todayProblem.problemCode});
    const [isFirst, setIsFirst] = useState(false);
    const [isLast, setIsLast] = useState(false);
    const [prevProblem, setPrevProblem] = useState('');
    const [nextProblem, setNextProblem] = useState('');
    const [todayProblemList, setTodayProblemList] = useState([]);
    const [imageList, setImageList] = useState([]);
    const [count, setCount] = useState(0);
    const [count2, setCount2] = useState(0);
    const [more, setMore] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [err, setErr] = useState();
    const [problemOrder, setProblemOrder] = useState();
    const [showPoem, setShowPoem] = useState(false);

    const getTodayProblemList = async () => {
        try {
            const response = await axiosapi.get('/home/todayproblem/more/', {
                params: {
                    id: todayProblem.id,
                    problemCode: todayProblem.problemCode
                }
            });
            response.data && setTodayProblemList(response.data.todayProblemList);
            response.data && response.data.imageList.length > 0 && setImageList(response.data.imageList);
            setIsLoaded(true);
            response.data && response.data.todayProblemList.map(prob => {
                if (prob.id === todayProblem.id) {
                    {
                        setProblemOrder(prob.problemOrder);
                        setCount2(count2+1);
                    }
                }
            })
        } catch(err) {
            setErr(err);
            if (err.response) {
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            } else {
                console.log(`Error: ${err.message}`);
            }
        }
    }

    
    useEffect (() => {
        getTodayProblemList();
    },[todayProblem])

    useEffect (() => {
        if (problemOrder === 1) {
            setIsFirst(true);
            setPrevProblem(todayProblem);
        }
        else if (todayProblemList.length === problemOrder) {
            setIsLast(true);
            setNextProblem(todayProblem);
        }
        else {
            setIsFirst(false);
            setIsLast(false);
            todayProblemList.map(prob => {
                if (prob.problemOrder === problemOrder-1) {
                    setPrevProblem(prob);
                }
                if (prob.problemOrder === problemOrder+1) {
                    setNextProblem(prob);
                }
            })
        }
    },[count2])

    if (errP) {
        return (
            <div className="problem">
                <p>"문제를 다운로드하지 못했습니다. 다시 시도해 주세요."</p>
                <button 
                    className='button'
                    onClick={() => navigateto(-1, {replace: true})}
                >
                    이전화면으로 돌아가기
                </button>
            </div>
        )
    }

    const splitContent = (sentence) => {
        const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
        const matches = sentence.matchAll(InlineMathRegex);
        let lastIndex = 0;
        const contentArray = [];
        
        for (const match of matches) {
            const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
            const restOfContent = sentence.substring(lastIndex, match.index);
        
            contentArray.push(restOfContent, InlineMathContent);
            lastIndex = match.index + match[0].length;
        }
        
        if (lastIndex < sentence.length) {
            contentArray.push(sentence.substring(lastIndex));
        }
        
        return <div>{contentArray}</div>;
    };

    if (problemPoem && problemPoem.length > 1) {
        return(
            <div className="problem" key={problem.problemCode}>
                <div className="content-fit">
                    <ProblemStatDescription 
                        topCategoryTitle="오늘의 문제"
                        problemTitle={todayProblem.todayProblemTitle} 
                        problemDescription={todayProblem.todayProblemDescription}
                        problemId={problem.problemCode}
                        useStat={useStat}
                        problemType={problem.problemType}
                        // problemPoemTitle={problemPoemTitle}
                        // problemPoem={problemPoem}
                    />
    
                    <p style={{marginLeft:"5px"}}>{todayProblem.publicationDate}</p>
                
                    {problem && <GeoGebraP
                        title={problem.problemTitle}
                        idIn={problem.problemCode}
                        customToolBarIn={problem.customToolBar}
                        allowStyleBarIn={problem.allowStyleBar}
                        ggbBase64In={problem.problemContent}
                        problemType={problem.problemType}
                        showToolBarHelpIn={problem.showToolBarHelp}
                        enableLabelDragIn={problem.enableLabelDrag}
                        enableShiftDragZoomIn={problem.enableShiftDragZoom}
                    />}
                
                    <div className='horizontal-flex-container'>
                        {isFirst 
                            ? null 
                            : isLoaded && <button 
                                    className='button'
                                    onClick={() => navigateto(`/todayproblem/${prevProblem.urlCode}`, {state:{
                                        "todayProblemList": todayProblemList,
                                        "todayProblem": prevProblem
                                    }})}
                                >
                                    이전 문제
                                </button>
                        } 
                        {isLast 
                            ? null 
                            : isLoaded && <button 
                                    className='button'
                                    onClick={() => navigateto(`/todayproblem/${nextProblem.urlCode}`, {state:{
                                        "todayProblemList": todayProblemList,
                                        "todayProblem": nextProblem
                                    }})}
                                >
                                    다음 문제
                                </button>
                                
                        }
    
    
                        {(problem.problemType === 1 || problem.problemType === 2 || problem.problemType === 6 || problem.problemType === 7 || problem.problemType === 701)
                            ? <button
                                    className='button'
                                    onClick={
                                        () => auth?.accessToken
                                            ? navigateto(`/todayproblem/my/s/list`, {state:{
                                                "todayProblemList": todayProblemList,
                                                "problem": problem,
                                                "todayProblem": todayProblem,
                                                "useStat": useStat,
                                                // "myAnswerList": myAnswerList,
                                                "isFirst": isFirst,
                                                "isLast": isLast,
                                                "prevProblem": prevProblem,
                                                "nextProblem": nextProblem,
                                                "proID": proID
                                            }})
                                            : navigateto("/login", {state:{"from": location}}, {replace: true})
                                    }
                                >
                                    내 풀이보기
                                </button>
                            : null
                        }
    
                        <button
                            className='button'
                            onClick={
                                () => auth?.accessToken
                                    // ? (isMyAnswer 
                                    ? navigateto(`/todayproblem/my/design/problem/list`, {state:{
                                        "todayProblemList": todayProblemList,
                                        "todayProblem": todayProblem,
                                    }})
                                        // : alert('문제를 푼 기록이 없습니다.'))
                                    : navigateto("/login", {state:{"from": location}}, {replace: true})
                            }
                        >
                            내 그림 수정하기
                        </button>
    
                        <button
                            className='button'
                            onClick={
                                () => auth?.accessToken
                                    ? navigateto(`/todayproblem/image/list/`, {state:{
                                        "todayProblemList": todayProblemList,
                                        "todayProblem": todayProblem,
                                        "problem":problem
                                    }})
                                    : navigateto("/login", {state:{"from": location}}, {replace: true})
                            }
                        >
                            그림보기
                        </button>

                        <button
                            className='button'
                            onClick={() => setShowPoem(prev => !prev)}
                        >
                            {showPoem ? '시적인 확장 감추기' : ' 시적인 확장 보기'}
                        </button>
                    </div>
                    <br/>
                    {showPoem && <div style={{marginLeft:"30px"}}>
                        <p><b>{problemPoem && problemPoem.length > 1 && problemPoemTitle !== "NaN" && splitContent(problemPoemTitle)}</b></p>
                        {problemPoem && problemPoem.length > 2 && problemPoem.map(poem => {
                            return (
                                <div>{poem.trim().length>0 ? splitContent(poem) : <br/>}</div>
                                // <div>{poem}<br/></div>
                            )
                        })}
                    </div>}
                    <br/>
                </div>
            </div>
        );
    } else {
        return(
            <div className="problem" key={problem.problemCode}>
                <div className="content-fit">
                    <ProblemStatDescription 
                        topCategoryTitle="오늘의 문제"
                        problemTitle={todayProblem.todayProblemTitle} 
                        problemDescription={todayProblem.todayProblemDescription}
                        problemId={problem.problemCode}
                        useStat={useStat}
                        problemType={problem.problemType}
                    />

                    <p style={{marginLeft:"5px"}}>{todayProblem.publicationDate}</p>
                
                    {problem && <GeoGebraP
                        title={problem.problemTitle}
                        idIn={problem.problemCode}
                        customToolBarIn={problem.customToolBar}
                        allowStyleBarIn={problem.allowStyleBar}
                        ggbBase64In={problem.problemContent}
                        problemType={problem.problemType}
                        showToolBarHelpIn={problem.showToolBarHelp}
                        enableLabelDragIn={problem.enableLabelDrag}
                        enableShiftDragZoomIn={problem.enableShiftDragZoom}
                    />}
                
                    <div className='horizontal-flex-container'>
                        {isFirst 
                            ? null 
                            : isLoaded && <button 
                                    className='button'
                                    onClick={() => navigateto(`/todayproblem/${prevProblem.urlCode}`, {state:{
                                        "todayProblemList": todayProblemList,
                                        "todayProblem": prevProblem
                                    }})}
                                >
                                    이전 문제
                                </button>
                        } 
                        {isLast 
                            ? null 
                            : isLoaded && <button 
                                    className='button'
                                    onClick={() => navigateto(`/todayproblem/${nextProblem.urlCode}`, {state:{
                                        "todayProblemList": todayProblemList,
                                        "todayProblem": nextProblem
                                    }})}
                                >
                                    다음 문제
                                </button>
                                
                        }


                        {(problem.problemType === 1 || problem.problemType === 2 || problem.problemType === 6 || problem.problemType === 7 || problem.problemType === 701)
                            ? <button
                                    className='button'
                                    onClick={
                                        () => auth?.accessToken
                                            ? navigateto(`/todayproblem/my/s/list`, {state:{
                                                "todayProblemList": todayProblemList,
                                                "problem": problem,
                                                "todayProblem": todayProblem,
                                                "useStat": useStat,
                                                // "myAnswerList": myAnswerList,
                                                "isFirst": isFirst,
                                                "isLast": isLast,
                                                "prevProblem": prevProblem,
                                                "nextProblem": nextProblem,
                                                "proID": proID
                                            }})
                                            : navigateto("/login", {state:{"from": location}}, {replace: true})
                                    }
                                >
                                    내 풀이보기
                                </button>
                            : null
                        }

                        <button
                            className='button'
                            onClick={
                                () => auth?.accessToken
                                    // ? (isMyAnswer 
                                    ? navigateto(`/todayproblem/my/design/problem/list`, {state:{
                                        "todayProblemList": todayProblemList,
                                        "todayProblem": todayProblem,
                                    }})
                                        // : alert('문제를 푼 기록이 없습니다.'))
                                    : navigateto("/login", {state:{"from": location}}, {replace: true})
                            }
                        >
                            내 그림 수정하기
                        </button>

                        <button
                            className='button'
                            onClick={
                                () => auth?.accessToken
                                    ? navigateto(`/todayproblem/image/list/`, {state:{
                                        "todayProblemList": todayProblemList,
                                        "todayProblem": todayProblem,
                                        "problem":problem
                                    }})
                                    : navigateto("/login", {state:{"from": location}}, {replace: true})
                            }
                        >
                            그림보기
                        </button>
                    </div>
                </div>
            </div>
        );
    };
};

export default TodayProblemFromImage;