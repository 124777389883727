import { useState, useEffect } from "react";
import axiosapi from "../api/axiosapi";

const GetProblemInfo = ({problemID}) => {
    const [problem, setProblem] = useState('');
    const [problemPoemTitle, setProblemPoemTitle] = useState('');
    const [problemPoem, setProblemPoem] = useState([]);
    const [count, setCount] = useState(0);
    const [isPoem, setIsPoem] = useState(false);
    const [errP, setErrP] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        // 메모리 관리를 위해 useEffect를 관리하는 함수
        const controller = new AbortController();

        setErrP(false);

        // 문제를 서버에서 array로 가져오는 함수
        async function fetchProblem () {
            try {
                const response = await axiosapi.get('/problem/'+problemID);
                response.data && setProblem(response.data);
                response.data && setCount(count+1);
                let problemPoemTitleR = response.data.problemPoemTitle ?? ""
                setProblemPoemTitle(problemPoemTitleR);
                let problemPoemR = response.data.problemPoem ? response.data.problemPoem.split("|") : "";
                setProblemPoem(problemPoemR);
                response.data.problemPoem ? setIsPoem(false) : setIsPoem(true);
            } catch (e) {
                setErrP(true);
                if (e.response) {
                    console.log(e.response.data);
                    console.log(e.response.status);
                    console.log(e.response.headers);
                } else {
                    console.log(`Error: ${e.message}`);
                }
            } finally {
                setLoading(false);
            }
        };
        fetchProblem();

        // useEffect를 클린업하는 함수
        return () => controller.abort();
    },[problemID]);

    console.log("isPoem at get", isPoem);

    return {problem, problemPoemTitle, problemPoem, isPoem, errP, loading}
}

export default GetProblemInfo;