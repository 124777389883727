import { React, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

const splitContent = (sentence) => {
    const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
    const matches = sentence.matchAll(InlineMathRegex);
    let lastIndex = 0;
    const contentArray = [];
  
    for (const match of matches) {
        const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
        const restOfContent = sentence.substring(lastIndex, match.index);
    
        contentArray.push(restOfContent, InlineMathContent);
        lastIndex = match.index + match[0].length;
    }
  
    if (lastIndex < sentence.length) {
        contentArray.push(sentence.substring(lastIndex));
    }
  
    return <div>{contentArray}</div>;
};

const ProblemStatDescription = (props) => {
    const { auth } = useAuth();
    const [problemDescriptionView, setProblemDescriptionView] = useState([]);
    // const sentence = "정사각형의 안쪽으로 넓이가 정사각형의 <InlineMath>\\frac{9}{29}</InlineMath>인 정사각형을 <InlineMath>x^2 + y^2 </InlineMath> 그리세요.";

    // const containerStyle = {
    //     whiteSpace: 'nowrap',
    //     maxWidth: '1520px',
    // };

    useEffect (() => {
        let tempView = props.problemDescription && props.problemDescription.split(/<br>|\|/);
        // let tempView = props.problemDescription && props.problemDescription.split('<br>');
        setProblemDescriptionView(tempView);
    }, [props.problemDescription])

    return(
        <div>
            {<div style={{fontSize:24,fontWeight:900, marginTop:"15px", marginBottom:"25px"}}>
                <div>
                    {props.problemTitle && splitContent(props.problemTitle)}
                </div>
            </div>}

            {props.problemPoem && <div style={{marginLeft:"5px"}}>
                {props.problemPoem.length > 1 && <div>
                        <p>수학과 시는 겉보기엔 다르지만, 내면에는 깊은 연결고리가 있습니다. 수학은 논리와 추론의 언어로 세상을 설명하고, 시는 함축과 은유의 언어로 감성을 표현합니다. 하지만 둘 다 제한된 형식 속에서 무한한 상상력을 펼친다는 공통점을 지닙니다. 수학의 간결한 공식은 시의 함축적인 언어처럼 진리를 담고 있으며, 시의 리듬과 운율은 수학의 정교한 구조처럼 아름다움을 선사합니다. 즉, 수학과 시는 각각 논리와 감성이라는 두 개의 날개로 인간의 지성과 감성을 풍요롭게 하는 예술이라 할 수 있습니다.</p>
                        <p>티프매쓰에서는 수학이 시가 되고, 시는 영감을 주고, 수학은 지성과 감성의 풍요로운 완성이 됩니다.</p>
                        <p>시를 감상하며 상상력과 직관력을 한껏 고양시켜 보세요.</p>
                </div>}
                <p><b>{props.problemPoemTitle !== undefined && props.problemPoemTitle !== "NaN" && splitContent(props.problemPoemTitle)}</b></p>
                {props.problemPoem.length > 1 && props.problemPoem.map(poem => {
                    return (
                        <div>{poem.trim().length>0 ? splitContent(poem) : <br/>}</div>
                        // <div>{poem}<br/></div>
                    )
                })}
            </div>}
            {props.problemPoem && <div style={{marginTop:"15px", marginLeft:"5px"}}>
                {props.problemPoem.length > 1 && <p><b>준비가 되었나요? 이제 문제를 풀어보세요.</b></p>}
            </div>}


            {<div style={{marginLeft:"5px", marginBottom:"15px"}}>
                {problemDescriptionView && problemDescriptionView.map(view => (
                    <div style={{marginBottom:"6px"}}>
                        {
                            view.includes("제목:")
                            ? <p style={{fontSize:16, fontWeight:700, marginBottom:"10px"}}>{view}</p>
                            : view.trim().length>0 ? splitContent(view) :""
                        }
                    </div>
                ))}
            </div>}
            {/* <div style={containerStyle}>
                {splitContent(sentence)}
            </div> */}
            {props.problemType != 3 && props.problemType != 5 && props.problemType != 6 && <div style={{marginLeft:"5px"}}>
                {props.useStat
                    ? props.useStat.triedUsersNum === 0
                        ? "아무도 도전한 사람이 없습니다. 문제를 푸는 첫 번째 도전자가 되세요."
                        : props.useStat.successfulUserNum === 0 
                            ? `${props.useStat.triedUsersNum}명이 도전했으나 아직 문제를 풀어낸 사람이 아직 없습니다. 문제를 푸는 첫 번째 사용자가 되시기 바랍니다.`
                            : `${props.useStat.triedUsersNum}명이 도전해 ${props.useStat.successfulUserNum}명이 문제를 풀었습니다. 문제를 풀어낸 ${(props.useStat.successfulUserNum/props.useStat.triedUsersNum*100).toFixed(2)}%의 사용자와 같은 자리를 차지하세요.`
                    : "아무도 도전한 사람이 없습니다. 문제를 푸는 첫 번째 도전자가 되세요."
                }
            </div>}
        
            {props.problemType != 3 && props.problemType != 5 && props.problemType != 6 && <div style={{marginLeft:"5px"}}>
                {props.useStat.myTryNum && props.useStat.myTryNum !== 0
                    ? <div>회원님은 {props.useStat.myTryNum}번을 시도해 {props.useStat.mySuccessNum}번 문제를 풀어냈습니다.</div>
                    : null
                }
                {/* {props.trialno && <div>{auth?.username}님은 {props.trialno.trialno1}번을 시도해 {props.trialno.trialno2}번 문제를 풀어냈습니다. 
                마지막 시도는 {props.trialno.trialno4 === 1 ? (props.trialno.trialno5 <= 1 ? "틀리지 않고 바로 문제를 해결했습니다" : `${props.trialno.trialno5} 번만에 문제를 해결했습니다.`) : `${props.trialno.trialno5} 번을 시도했으나 문제를 풀지 못하고 마쳤습니다.`}</div>} */}
            </div>}
            <br/>
        </div>
    )
}

export default ProblemStatDescription;