import React, {useState, useEffect} from 'react';
import GeoGebraP from '../components/GeoGebraP';
import { useLocation, useNavigate, Link } from 'react-router-dom';
// import axiosapi from '../api/axiosapi';
// import './mathsubject.css';
// import '../App.css';
import GetProblemInfo from '../components/GetProblemInfo';
import ProblemStatDescription from '../components/ProblemStatDescription';
// import useAuth from '../hooks/useAuth';
import UserSolvingStat from '../components/UserSolvingStat';
import { VscCheck,VscRocket } from "react-icons/vsc";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

const ExerciseCalculationSample = ()=> {
    const {categoryCode, categoryTitle, problemCode} = useLocation().state;
    // const {auth} = useAuth();
    const navigateto=useNavigate();
    const location = useLocation();
    const [category, setCategory] = useState('');
    const [count, setCount] = useState(0);
    // const [error2, setError2]=useState();
    const {problem, problemPoemTitle, problemPoem, errP} = GetProblemInfo({"problemID": problemCode});
    const [images, setImages] = useState([]);
    const {useStat} = UserSolvingStat({"problemCode": problemCode});
    const axiosPrivate = useAxiosPrivate();
    const [isPoem, setIsPoem] = useState(false);
    const [showPoem, setShowPoem] = useState(false);

    const splitContent = (sentence) => {
        const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
        const matches = sentence.matchAll(InlineMathRegex);
        let lastIndex = 0;
        const contentArray = [];
        
        for (const match of matches) {
            const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
            const restOfContent = sentence.substring(lastIndex, match.index);
        
            contentArray.push(restOfContent, InlineMathContent);
            lastIndex = match.index + match[0].length;
        }
        
        if (lastIndex < sentence.length) {
            contentArray.push(sentence.substring(lastIndex));
        }
        
        return <div>{contentArray}</div>;
    };

    useEffect(()=> {
        let isMounted = true;
        const controller = new AbortController();

        const fetchCategoryList = async () => {
            try {
                const response = await axiosPrivate.get('/category/problem/all/sample/', {
                    params: {
                        category_code: categoryCode
                    },
                    signal: controller.signal
                })
                isMounted && response.data && setCategory(response.data);
                isMounted && response.data && setCount(count+1);
            } catch (err) {
                err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
                // navigateto('/login', { state: { 
                //     from: location, 
                //     categoryCode: categoryCode,
                //     categoryTitle: categoryTitle,
                //     problemCode: problemCode
                // }, replace: true });
                // setError2(e)
                // if (e.response) {
                //     console.log(e.response.data);
                //     console.log(e.response.status);
                //     console.log(e.response.headers);
                // } else {
                //     console.log(`Error: ${e.message}`);
                // }
            }
        }

        fetchCategoryList()

        return () => {
            isMounted = false;
            controller.abort();
        }
    },[categoryCode]);

    useEffect(() => {
        const poemSetting = () => {
            problemPoem && problemPoem.length > 1 ? setIsPoem(true) : setIsPoem(false)
        }

        const imagesSetting = () => {
            setImages([]);
            problem.images && problem.images.map(image => {
                setImages(prev => [...prev, image]);
            })
        }

        setShowPoem(false);

        poemSetting();
        imagesSetting();
    },[problemPoemTitle, problemPoem])

    function ProblemLinkC ({LinkTitle, LinkId}) {
        return(
            <div className="subject-sidebar-4">
                {LinkId === problemCode
                    ? <b style ={{color:'red'}}>{LinkTitle} <VscCheck/> <VscRocket/></b>
                    : <Link to={`/calculationexercise/sample/`} state={{
                                "categoryCode": categoryCode,
                                "categoryTitle": categoryTitle,
                                "problemCode": LinkId
                            }}>
                                {LinkTitle}
                        </Link>
                }
            </div>
        )
    }

    function ProblemLink ({LinkTitle, LinkId}) {
        return(
            <div className="subject-sidebar-4">
                <Link to={`/calculationexercise/sample/`} state={{
                        "categoryCode": categoryCode,
                        "categoryTitle": categoryTitle,
                        "problemCode": LinkId
                    }}>
                        {LinkTitle}
                </Link>
            </div>
        )
    }

    if (errP) {
        return (
            <div className="problem">
                <p>"문제를 다운로드하지 못했습니다. 다시 시도해 주세요."</p>
                <button 
                    className='button'
                    onClick={() => navigateto(-1, {replace: true})}
                >
                    이전화면으로 돌아가기
                </button>
            </div>
        )
    }

    return(
        <div className="App-full-row-container" key={problem.problemCode}>
            <div className="left-menu-box-1">
                <h4 style={{fontWeight:"bolder", color:"orange" ,marginTop:"22px", marginBottom:"15px", marginLeft:"5px"}}>
                    {categoryTitle}
                </h4>
                {
                    category && category.map(problem => {
                        return(
                            <div key={problem.problemCode}>
                                {problem.problemCode === problemCode
                                    ? <ProblemLinkC LinkTitle={problem.problemTitle} LinkId={problem.problemCode} /> 
                                    : <ProblemLink LinkTitle={problem.problemTitle} LinkId={problem.problemCode} />
                                }
                            </div>
                        )
                    })
                }
            </div>
            <div className="content-box-1">
                <div className="problem">
                    <div style={{minWidth: "400px"}}></div>
                    <div className="content-fit">
                        <ProblemStatDescription 
                            topCategoryTitle={categoryTitle}
                            problemTitle={problem.problemTitle} 
                            problemDescription={problem.problemDescription}
                            problemId={problem.idIn}
                            useStat={useStat}
                            problemType={problem.problemType}
                        />
                        {problem && <GeoGebraP
                            title={problem.problemTitle}
                            idIn={problem.problemCode}
                            customToolBarIn={problem.customToolBar}
                            allowStyleBarIn={problem.allowStyleBar}
                            ggbBase64In={problem.problemContent}
                            problemType={problem.problemType}
                            showToolBarHelpIn={problem.showToolBarHelp}
                            enableLabelDragIn={problem.enableLabelDrag}
                            enableShiftDragZoomIn={problem.enableShiftDragZoom}
                        />}
                        <div className='horizontal-flex-container'>
                            {/* {problem.problemFileType === 1 && (problem.problemType === 1 || problem.problemType === 2 || problem.problemType === 4 || problem.problemType === 6 || problem.problemType === 7 || problem.problemType === 701 || problem.problemType === 10 || problem.problemType === 11 || problem.problemType === 12)
                                ? <button 
                                        className='button'
                                        onClick={
                                            () => auth?.accessToken
                                                ? navigateto(`/mathsubject/my/s/list`, {state:{
                                                    "subjectCode": subjectCode,
                                                    "problemCode": problemCode,
                                                    "subjectTitle": subjectTitle,
                                                    "subject":subject,
                                                    "problem": problem,
                                                    "problemCategory": problemCategory,
                                                    "useStat": useStat,
                                                    "firstProbID": firstProbIDSample,
                                                    "isSample": isSample
                                                }})
                                                : navigateto("/login", {state:{"from": location}}, {replace: true})
                                        }
                                    >
                                        내 풀이보기
                                    </button>
                                : null
                            } */}
                            {isPoem && <button
                                    className='button'
                                    onClick={() => setShowPoem(prev => !prev)}
                                >
                                    {showPoem ? '시적인 확장 감추기' : '시적인 확장 보기'}
                                </button>
                            }
                            {/* {images.length > 0 && <button 
                                    className='button'
                                    onClick={() => navigateto(`/mathsubject/image/list/`, {state:{
                                        "subjectCode": subjectCode,
                                        "problemCode": problemCode,
                                        "subjectTitle": subjectTitle,
                                        "firstProbID": firstProbIDSample,
                                        "problemTitle": problem.problemTitle,
                                        "images": images,
                                        "isSample": isSample
                                    }})}
                                >
                                    그림보기
                                </button>
                            } */}
                        </div>
                        <br/>
                        {showPoem && <div style={{marginLeft:"30px"}}>
                            <p><b>{problemPoem && problemPoem.length > 1 && problemPoemTitle !== "NaN" && splitContent(problemPoemTitle)}</b></p>
                            {problemPoem && problemPoem.length > 1 && problemPoem.map(poem => {
                                return (
                                    <div>{poem.trim().length>0 ? splitContent(poem) : <br/>}</div>
                                )
                            })}
                        </div>}
                        <br/>
                    </div>
                </div>
            </div>
            <br/>
        </div>
    );
};

export default ExerciseCalculationSample;