import React, {useState, useEffect} from 'react';
import GeoGebraP from '../components/GeoGebraP';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import SideBar from '../components/SideBar';
// import axiosapi from '../api/axiosapi';
// import './mathsubject.css';
// import '../App.css';
import GetProblemInfo from '../components/GetProblemInfo';
import ProblemStatDescription from '../components/ProblemStatDescription';
import useAuth from '../hooks/useAuth';
import UserSolvingStat from '../components/UserSolvingStat';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

const MathSubject = ()=> {
    const {firstProbID}=useParams();
    const {subjectCode, problemCode, subjectTitle} = useLocation().state;
    const {auth} = useAuth();
    const location = useLocation();
    const [subject, setSubject] = useState('');
    const [count, setCount] = useState(0);
    // const [error2, setError2]=useState();
    const navigateto=useNavigate();
    const {problem, problemPoemTitle, problemPoem, errP} = GetProblemInfo({"problemID": problemCode});
    const [problemCategory, setProblemCategory] = useState();
    const [images, setImages] = useState([]);
    const isSample = false;
    const {useStat} = UserSolvingStat({"problemCode": problemCode});
    const axiosPrivate = useAxiosPrivate();
    // const [isMounted, setIsMounted] = useState(false);
    const [errFD, setErrFD] = useState(false);
    const [isPoem, setIsPoem] = useState(false);
    const [showPoem, setShowPoem] = useState(false);

    const splitContent = (sentence) => {
        const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
        const matches = sentence.matchAll(InlineMathRegex);
        let lastIndex = 0;
        const contentArray = [];
        
        for (const match of matches) {
            const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
            const restOfContent = sentence.substring(lastIndex, match.index);
        
            contentArray.push(restOfContent, InlineMathContent);
            lastIndex = match.index + match[0].length;
        }
        
        if (lastIndex < sentence.length) {
            contentArray.push(sentence.substring(lastIndex));
        }
        
        return <div>{contentArray}</div>;
    };

    useEffect(()=> {
        let isMounted = true;
        const controller = new AbortController();

        setErrFD(false);

        const fetchSubjectList = async () => {
            try {
                const response = await axiosPrivate.get('/subjectproblem/', {
                    params: {
                        subject_code: subjectCode
                    },
                    signal: controller.signal
                })
                // console.log("주제별 문제 목록 :", response.data);
                isMounted && response.data && setSubject(response.data);
                isMounted && response.data && setCount(count+1);
            } catch (err) {
                setErrFD(true);
                (auth?.accessToken === 'notoken' || !auth?.accessToken) && err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
                // setError2(e)
                // if (e.response) {
                //     console.log(e.response.data);
                //     console.log(e.response.status);
                //     console.log(e.response.headers);
                // } else {
                //     console.log(`Error: ${e.message}`);
                // }
            }
        }
        fetchSubjectList()
        return () => {
            isMounted = false;
            controller.abort();
        }
    },[subjectCode]);

    useEffect(() => {
        const problemCategorySetting = () => {
            subject && subject.categories.map(cate => {
                cate.problems.map(prob => {
                    if(prob.problemCode === problemCode) {
                        setProblemCategory(cate.categoryCode);
                    }
                })
            })
        }
        problemCategorySetting();
    },[count, problem])

    useEffect(() => {
        const poemSetting = () => {
            problemPoem && problemPoem.length > 1 ? setIsPoem(true) : setIsPoem(false)
        }

        const imagesSetting = () => {
            setImages([]);
            problem.images && problem.images.map(image => {
                setImages(prev => [...prev, image]);
            })
        }

        setShowPoem(false);

        poemSetting();
        imagesSetting();
    },[problemPoemTitle, problemPoem])

    console.log("problemPoemTitle", problemPoemTitle);
    console.log("problemPoem", problemPoem);
    console.log("isPoem", isPoem);
    console.log("showPoem", showPoem)

    if (auth?.accessToken === undefined) {
        return (
            navigateto("/login", {state:{"from": location}}, {replace: true})
        )
    } else if (auth?.userRole2 !== "premium") {
        return (
            <div className="problem">
                <h2>"유료회원이 이용하는 서비스입니다."</h2>
                <br></br>
                <p>티프매쓰는 온라인에서 가장 많은 작도 문제를 제공하는 웹사이트입니다.</p>
                <p>작도툴을 사용하는 방법을 익힌 다음 기본적인 주제부터 고급그런 주제에 이르는 풍성한 작도 문제를 통해 두뇌 활동을 촉진하세요.</p>
                <p>체계적인 사고력을 기반으로 상상력과 직관력을 최고 수준으로 끌어올리는 사고실험의 세계에 풍덩 빠져보시기 바랍니다.</p>
                <p>이용권을 구매하면 자동으로 유료회원이 되고, 세계에서 가장 많은 작도 문제들을 만날 수 있습니다.</p>
                <div className='horizontal-flex-container'>
                    <button 
                        className='button'
                        onClick={() => navigateto("/payment/1")}
                    >
                        이용권 구매하기
                    </button>
                    <button 
                        className='button'
                        onClick={() => navigateto(-1, {replace: true})}
                    >
                        이전화면으로 이동
                    </button>
                </div>
            </div>
        )
    } else if (errP || errFD) {
        return (
            <div className="problem">
                <p>"문제를 다운로드하지 못했습니다. 다시 시도해 주세요."</p>
                <button 
                    className='button'
                    onClick={() => navigateto(-1, {replace: true})}
                >
                    이전화면에서 다시 시도
                </button>
            </div>
        )
    }

    return(
        <div className="App-full-row-container" key={problem.problemCode}>
            <div className="left-menu-box-1">
                {problem && problemCategory && <SideBar 
                    topCategory={subjectCode}
                    problemCate={problemCategory} 
                    categories={subject.categories}
                    problemID={problem.problemCode}
                    topCategoryTitle={subject.subjectTitle}
                    problemId={problem.problemCode}
                    isSample={isSample}
                />}
            </div>
            <div className="content-box-1">
                <div className="problem">
                    <div style={{minWidth: "400px"}}></div>
                    <div className="content-fit">
                        <ProblemStatDescription 
                            topCategoryTitle={subjectTitle}
                            problemTitle={problem.problemTitle} 
                            problemDescription={problem.problemDescription}
                            problemId={problem.idIn}
                            useStat={useStat}
                            problemType={problem.problemType}
                        />
                        {problem && problem.problemFileType === 1 && <GeoGebraP
                            title={problem.problemTitle}
                            idIn={problem.problemCode}
                            customToolBarIn={problem.customToolBar}
                            allowStyleBarIn={problem.allowStyleBar}
                            ggbBase64In={problem.problemContent}
                            problemType={problem.problemType}
                            showToolBarHelpIn={problem.showToolBarHelp}
                            enableLabelDragIn={problem.enableLabelDrag}
                            enableShiftDragZoomIn={problem.enableShiftDragZoom}
                        />}
                        <div className='horizontal-flex-container'>
                            {problem.problemFileType === 1 && (problem.problemType === 1 || problem.problemType === 2 || problem.problemType === 4 || problem.problemType === 6 || problem.problemType === 7 || problem.problemType === 701 || problem.problemType === 10 || problem.problemType === 11 || problem.problemType === 12)
                                ? <button 
                                        className='button'
                                        onClick={
                                            () => auth?.accessToken
                                                ? navigateto(`/mathsubject/my/s/list`, {state:{
                                                    "subjectCode": subjectCode,
                                                    "problemCode": problemCode,
                                                    "subjectTitle": subjectTitle,
                                                    "subject":subject,
                                                    "problem": problem,
                                                    "problemCategory": problemCategory,
                                                    "useStat": useStat,
                                                    "firstProbID": firstProbID,
                                                    "isSample": isSample
                                                }})
                                                : navigateto("/login", {state:{"from": location}}, {replace: true})
                                        }
                                    >
                                        내 풀이보기
                                    </button>
                                : null
                            }
                            {isPoem && <button
                                    className='button'
                                    onClick={() => setShowPoem(prev => !prev)}
                                >
                                    {showPoem ? '시적인 확장 감추기' : ' 시적인 확장 보기'}
                                </button>
                            }
                            {images.length > 0 && <button 
                                    className='button'
                                    onClick={() => navigateto(`/mathsubject/image/list/`, {state:{
                                        "subjectCode": subjectCode,
                                        "problemCode": problemCode,
                                        "subjectTitle": subjectTitle,
                                        "firstProbID": firstProbID,
                                        "problemTitle": problem.problemTitle,
                                        "images": images,
                                        "isSample": isSample
                                    }})}
                                >
                                    그림보기
                                </button>
                            }
                        </div>
                        <br/>
                        {showPoem && <div style={{marginLeft:"30px"}}>
                            <p><b>{problemPoem && problemPoem.length > 1 && problemPoemTitle !== "NaN" && splitContent(problemPoemTitle)}</b></p>
                            {problemPoem && problemPoem.length > 2 && problemPoem.map(poem => {
                                return (
                                    <div>{poem.trim().length>0 ? splitContent(poem) : <br/>}</div>
                                    // <div>{poem}<br/></div>
                                )
                            })}
                        </div>}
                        <br/>
                    </div>
                </div>
            </div>
            <br/>
        </div>
    );
};

export default MathSubject;