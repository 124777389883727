import { React, useEffect, useState } from 'react';
import GeoGebraP from '../components/GeoGebraP';
import { useParams } from 'react-router-dom';
import axiosapi from "../api/axiosapi";
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';
import { Helmet } from 'react-helmet';

const DoorTodayProblem = ()=> {
    const {todayDoorURL}=useParams();
    const [todayProblemInfo, setTodayProblemInfo] = useState('');
    const [todayProblemDescriptionData, setTodayProblemDescriptionData] = useState([]);
    const [problemPoem, setProblemPoem] = useState([]);
    const [problemPoemTitle, setProblemPoemTitle] = useState('');
    const [isPoem, setIsPoem] = useState(false);
    const [showPoem, setShowPoem] = useState(false);

    useEffect (() => {
        const getTodayProblemInfo = async () => {
            try {
                const response = await axiosapi.get('/door/todayproblem/', {
                    params: {
                        url: todayDoorURL
                    }
                })
                response.data && setTodayProblemInfo(response.data);
                const descriptionData = response.data.todayProblemDescription && response.data.todayProblemDescription.split(/<br>|\|/);
                setTodayProblemDescriptionData(descriptionData);
                response.data.problemPoem && setIsPoem(true);
                response.data.problemPoemTitle && setProblemPoemTitle(response.data.problemPoemTitle);
                const poemData = response.data.problemPoem && response.data.problemPoem.split("|");
                setProblemPoem(poemData);
            } catch (err) {
                console.log(err);
            }
        }

        getTodayProblemInfo();
    }, [todayDoorURL])

    const splitContent = (sentence) => {
        const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
        const matches = sentence.matchAll(InlineMathRegex);
        let lastIndex = 0;
        const contentArray = [];
      
        for (const match of matches) {
            const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
            const restOfContent = sentence.substring(lastIndex, match.index);
        
            contentArray.push(restOfContent, InlineMathContent);
            lastIndex = match.index + match[0].length;
        }
      
        if (lastIndex < sentence.length) {
            contentArray.push(sentence.substring(lastIndex));
        }
      
        return <div>{contentArray}</div>;
    };

    console.log("설명", todayProblemDescriptionData);

    return(
        <div className="problem" key={todayDoorURL}>
            <Helmet>
                <title>{todayProblemInfo.todayProblemTitle}</title>
                <meta name="description" content={todayProblemInfo.todayProblemDescription} />
                <meta property="og:title" content={todayProblemInfo.todayProblemTitle} />
                <meta property="og:description" content={todayProblemInfo.todayProblemDescription} />
                <meta property="og:url" content={"https://tiffmath.com/door/today/" + todayDoorURL} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={"https://tiffmath.com/image/"+todayProblemInfo.imageFile} />
            </Helmet>
            <div className="content-fit">
                {<div style={{fontSize:24, fontWeight:900, marginTop:"20px", marginBottom:"25px"}}>
                    <div>
                        {todayProblemInfo.todayProblemTitle && splitContent(todayProblemInfo.todayProblemTitle)}
                    </div>
                </div>}
                {<div style={{marginLeft:"5px", marginBottom:"15px"}}>
                    {todayProblemDescriptionData.length > 0 && todayProblemDescriptionData.map(todayDesciption => {
                        return(
                            <div style={{marginBottom:"7px"}}>
                                {
                                    todayDesciption.includes("제목:") 
                                    ? <p style={{fontSize:18, fontWeight:700, marginBottom:"10px"}}>{todayDesciption}</p>
                                    : todayDesciption.trim().length>0 ? splitContent(todayDesciption) : ""
                                }
                            </div>
                        )
                    })}
                </div>}
                {todayProblemInfo.problemType != 3 && <div style={{marginLeft:"5px"}}>
                    {todayProblemInfo.triedUsersNum === 0
                        ? "아무도 도전한 사람이 없습니다. 문제를 푸는 첫 번째 도전자가 되세요."
                        : todayProblemInfo.successfulUserNum === 0 
                            ? `${todayProblemInfo.triedUsersNum}명이 도전했으나 아직 문제를 풀어낸 사람이 아직 없습니다. 문제를 푸는 첫 번째 사용자가 되시기 바랍니다.`
                            : `${todayProblemInfo.triedUsersNum}명이 도전해 ${todayProblemInfo.successfulUserNum}명이 문제를 풀었습니다. 문제를 풀어낸 ${(todayProblemInfo.successfulUserNum/todayProblemInfo.triedUsersNum*100).toFixed(0)}%의 사용자와 같은 자리를 차지하세요.`
                    }
                </div>}

                <p style={{marginLeft:"5px"}}>{todayProblemInfo.publicationDate}</p>
            
                {todayProblemInfo && <GeoGebraP
                    title={todayProblemInfo.todayProblemTitle}
                    idIn={todayProblemInfo.problemCode}
                    customToolBarIn={todayProblemInfo.customToolBar}
                    allowStyleBarIn={todayProblemInfo.allowStyleBar}
                    ggbBase64In={todayProblemInfo.problemContent}
                    problemType={todayProblemInfo.problemType}
                    showToolBarHelpIn={todayProblemInfo.showToolBarHelp}
                    enableLabelDragIn={todayProblemInfo.enableLabelDrag}
                    enableShiftDragZoomIn={todayProblemInfo.enableShiftDragZoom}
                />}

                <div className='horizontal-flex-container'>
                    {isPoem && <button
                            className='button'
                            onClick={() => setShowPoem(prev => !prev)}
                        >
                            {showPoem ? '시적인 확장 감추기' : ' 시적인 확장 보기'}
                        </button>
                    }
                </div>

                <br/>
                {showPoem && <div style={{marginLeft:"30px"}}>
                    <p><b>{problemPoem && problemPoem.length > 1 && problemPoemTitle !== "NaN" && splitContent(problemPoemTitle)}</b></p>
                    {problemPoem && problemPoem.length > 2 && problemPoem.map(poem => {
                        return (
                            <div>{poem.trim().length>0 ? splitContent(poem) : <br/>}</div>
                            // <div>{poem}<br/></div>
                        )
                    })}
                </div>}
                <br/>
            
                {/* <div className='problemmove'>
                    {(problem.problemType === 1 || problem.problemType === 2 || problem.problemType === 6 || problem.problemType === 7)
                        ? <button
                                className='button'
                                onClick={
                                    () => auth?.accessToken
                                        ? navigateto(`/todayproblem/my/s/list`, {state:{
                                            "todayProblemList": todayProblemListV,
                                            "problem": problem,
                                            "todayProblem": todayProblem,
                                            "useStat": useStat,
                                            "isFirst": isFirst,
                                            "isLast": isLast,
                                            "prevProblem": prevProblem,
                                            "nextProblem": nextProblem,
                                            "proID": proID
                                        }})
                                        : navigateto("/login", {state:{"from": location}}, {replace: true})
                                }
                            >
                                내 풀이보기
                            </button>
                        : null
                    }

                    <button
                        className='button'
                        onClick={
                            () => auth?.accessToken
                                ? navigateto(`/todayproblem/image/list/`, {state:{
                                    "todayProblemList": todayProblemListV,
                                    "todayProblem": todayProblem,
                                    "problem":problem
                                }})
                                : navigateto("/login", {state:{"from": location}}, {replace: true})
                        }
                    >
                        그림보기
                    </button>
                </div> */}
            </div>
        </div>
    );
};

export default DoorTodayProblem;
